import bg_image from "./assets/bg.webp"
import logo from "./assets/logo.svg"

import React, { useState, useEffect } from 'react';

function App() {

  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const getTransform = () => {
    const offsetX = (position.x - window.innerWidth / 2) * 0.02;
    const offsetY = (position.y - window.innerHeight / 2) * 0.02;
    return `translate(${offsetX}px, ${offsetY}px)`;
  };

  return (
    <div style={{backgroundImage: `url(${bg_image})`, backgroundSize:'cover'}} className="w-full min-h-screen flex flex-col justify-center items-center font-rubik object-cover">
      <div className="w-full min-h-screen bg-gradient-from-b bg-gradient-to-t from-[#000000dd] to-[#00000033] flex flex-col justify-center items-center">
        <div className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3 flex flex-col justify-center p-4" style={{ transform: getTransform() }}>
          <img src={logo} alt="" className="w-full"/>
          <p className="text-2xl lg:text-4xl text-white uppercase font-medium flex flex-row justify-center">- Cooming Soon! -</p>
          <p className="text-lg lg:text-xl text-white mt-6 text-center">
          Save Terra is an Environmental Education Platform designed for everyone. Dive into a wide range of topics including climate change and negotiations. Engage with our interactive educational games to deepen your understanding and make learning fun and impactful.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
